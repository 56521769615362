<!--
 * @Description: 户端-首页-共享停车 - 宽高固定比例的盒子 sharePark
 * @Author: yanxiao
 * @Github: https://github.com/hzylyh
 * @Date: 2021-04-06 11:56:33
 * @LastEditors: yanxiao
-->
<template>
  <div :style="{position: 'relative', width: width, height: '0', paddingTop: height}">
    <div :style="{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ['width', 'height']
}
</script>
<style lang='less' scoped>
</style>
