<!--
 * @Description:  用户端-首页-共享停车 sharePark
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 13:04:20
 * @LastEditors: yanxiao
-->
<template>
  <div class='body'>
    <van-nav-bar title="共享停车"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="map-box">
      <shareParkMap @marketClick="marketClick"></shareParkMap>
    </van-row>
    <van-row>
      <van-popup v-model="popIsShow"
                 :overlay="false"
                 position="bottom"
                 :style="{ height: '50%',}">
        <van-row class="pop-container">
          <van-row class="pop-card"
                   v-for="(item,index) in data"
                   :key="index">
            <popCard :data="item"></popCard>
          </van-row>
        </van-row>
      </van-popup>
    </van-row>

    <div class="addBut"
         @click="issue">
      <van-image class="butImg"
                 :src="require('@/assets/homePage/sharePark/fabu.png')"></van-image>
      <div class="butText">我要发布</div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import shareParkMap from './components/shareParkMap'
import popCard from './components/popCard'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    shareParkMap,
    popCard
  },
  data () {
    // 这里存放数据
    return {
      popIsShow: true,
      data: [{
        title: '和谐小区',
        state: true,
        price: '300',
        distance: '200',
        address: '重庆市渝北区渝湖路137号重庆市渝北区渝湖路137号重庆市渝北区渝湖路137号'
      }, {
        title: '祥和小区',
        state: false,
        price: '300',
        distance: '200',
        address: '渝北区渝湖路137号重庆市渝北区'
      }, {
        title: '金城小区',
        state: true,
        price: '300',
        distance: '200',
        address: '重庆市渝北区渝湖路137号'
      }]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () {
  }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    marketClick (market) {
      console.log('点击了点标记', market)
      if (market.id === 1) {
        this.data = [...this.data, ...this.data]
      } else if (market.id === 2) {
        this.data = this.data.reverse()
      }

      this.popIsShow = true
    },
    issue () {
      this.$router.push({ name: 'shareIssue' })
    },
    handleClickTopBack () {
      this.$router.go(-1)
    }

  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.map-box {
  height: calc(100% - 46px);
}
.pop-container {
  height: 100%;
  width: 100%;
  overflow: auto;
  .pop-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: solid #ebebeb 1px;
  }
}
.addBut {
  position: fixed;
  right: 10px;
  bottom: 55px;
  padding: 10px 15px;
  border-radius: 25px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 1px rgb(236, 236, 236);
  z-index: 5000;
  .butImg {
    height: 30px;
    width: 30px;
    float: left;
    margin-right: 10px;
  }
  .butText {
    float: left;
    line-height: 30px;
    font-size: 15px;
    color: #19a9fc;
  }
}
</style>
