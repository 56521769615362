<!--
 * @Description: 户端-首页-共享停车 - 卡片组件 sharePark
 * @Author: yanxiao
 * @Github: https://github.com/hzylyh
 * @Date: 2021-04-06 11:56:33
 * @LastEditors: zhoucheng
-->
<template>
  <van-row class="card-main">
    <ratioBox width="20%"
              height='20%'>
      <van-image class="card-img"
                 fit="cover"
                 src="https://img01.yzcdn.cn/vant/cat.jpeg" />
    </ratioBox>
    <van-row class="card-content">
      <van-row>
        <span class="card-title">{{data.title}}</span>
        <span v-if="data.state"
              class="card-state">车位充足</span>
        <span v-else
              class="card-state"
              style="background: #F26B6A;">车位不足</span>
      </van-row>
      <van-row class="row2">
        <span>均价：￥<span class="blue-text">{{data.price}}</span>/月</span>
        <span>距离：<span class="blue-text">{{data.distance}}</span>米</span>
        <van-image style="width:8px;height:15px;float:right;margin-right:8px;"
                   :src="require('@/assets/homePage/sharePark/rigth-arrows.png')"></van-image>
      </van-row>
      <van-row>
        <div style="float: left;">
          <van-image style="width:8px;height:10px;margin-right:5px;"
                     :src="require('@/assets/homePage/sharePark/locationIco.png')"></van-image>
        </div>
        <div class="card-address">
          <span>{{data.address}}</span>
        </div>
      </van-row>
    </van-row>
  </van-row>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import ratioBox from './ratioBox'

export default {
  // import引入的组件需要注入到对象中才能使用
  props: ['data'],
  components: {
    ratioBox
  },
  data () {
    // 这里存放数据
    return {}
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {}
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.card-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  padding: 10px 0;
  .card-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
  .card-content {
    width: 80%;
    margin-left: 10px;
    overflow: hidden;
    .card-title {
      margin-right: 10px;
      padding: 3px 3px 0 0;
      font-size: 17px;
      font-weight: 800;
      color: #333333;
      vertical-align: middle;
    }
    .card-state {
      padding: 3px 3px 1px 3px;
      font-size: 13px;
      background: #82e77a;
      color: #ffffff;
      border-radius: 5px;
      vertical-align: middle;
    }
    .row2 {
      font-size: 17px;
      color: #333333;
    }
    .row2 > span {
      margin-right: 8px;
    }
    .blue-text {
      color: #19a9fc;
    }
    .card-address {
      float: left;
      width: 80%;
      font-size: 13px;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
