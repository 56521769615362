<!--
 * @Description: 用户端-首页-共享停车 - 地图组件 sharePark
 * @Author: yanxiao
 * @Github: https://github.com/hzylyh
 * @Date: 2021-04-06 09:59:33
<<<<<<< HEAD
 * @LastEditors: haojing
=======
 * @LastEditors: zhoucheng
>>>>>>> 9576cb5b08bc7562d3691e2d0e06321fb5e8af74
-->
<template>
  <div id="container">
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      map: null,
      markerList: [
        {
          id: 1,
          lng: 116.397428,
          lat: 39.90923,
          title: '点1'
        },
        {
          id: 2,
          lng: 116.394428,
          lat: 39.90923,
          title: '点2'
        },
        {
          id: 3,
          lng: 116.397428,
          lat: 39.90523,
          title: '点3'
        }
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.init()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    init () {
      this.map = new window.AMap.Map('container', {
        resizeEnable: true, // 允许拖动
        scrollWheel: false,
        zoom: 15, // 级别
        center: [116.397428, 39.90323], // 中心点坐标
        viewMode: '3D' // 使用3D视图
      })
      this.setMarker()
    },

    setMarker () {
      const list = []
      const icon = new window.AMap.Icon({
        size: new window.AMap.Size(25, 30), // 图标尺寸
        image: require('@/assets/homePage/sharePark/xiaoqv.png'), // Icon的图像
        imageOffset: new window.AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new window.AMap.Size(25, 30) // 根据所设置的大小拉伸或压缩图片
      })
      for (const item of this.markerList) {
        const marker = new window.AMap.Marker({
          position: new window.AMap.LngLat(item.lng, item.lat),
          offset: new window.AMap.Pixel(-10, -10),
          icon: icon, // 添加 Icon 图标 URL
          title: item.title
        })
        const self = this
        window.AMap.event.addListener(
          marker,
          'click',
          function (e, title = item.title, id = item.id) {
            self.$emit('marketClick', { title, id, e })
          }
        )
        list.push(marker)
      }
      this.map.add(list)
    }

  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
#container {
  width: 100%;
  height: 100%;
}
</style>
